import React, { useState, useEffect } from 'react';
import Navigation from './Navigation/Navigation';
import { Route, Switch, Redirect } from 'react-router-dom';
import Series from './Series/Series';
import Sports from './Sports/Sports';
import AllMatches from './Matches/MatchesBySport';
import Matches from './Matches/MatchesBySeries';
import Markets from './Markets/Markets';
import ManualMarkets from './ManualMarkets/ManualMarkets';
import ModifyOdds from './ModifyOdds/ModifyOdds';
import { useDispatch, useSelector } from "react-redux";
import * as actions from '../../store/actions/index';
import ChangePassword from './ChangePassword/ChangePasword';

const Layout = (props) => {

    const dispatch = useDispatch()
    const token = localStorage.getItem('token')
    const isAuthenticated = token !== null
    const authRedirectPath = useSelector(state => state.auth.authRedirectPath)

    useEffect(() => {
        dispatch(actions.authCheckState())
    })

    let secure = (
        <Switch>
            <Route path='/changePassword' component={ChangePassword} />
            <Route path='/sports' component={Sports} />
            <Route path='/series' component={Series} />
            <Route path='/allMatches' component={AllMatches} />
            <Route path='/markets/:eventId/:eventName' component={Markets} />
            <Route path='/matches/:seriesName/:eventType/:seriesId' component={Matches} />
            <Route path='/modifyOdds/:marketId' component={ModifyOdds} />
            <Route path='/' component={ManualMarkets} />
        </Switch>
    );


    if (!isAuthenticated)
        return <Redirect to={authRedirectPath} />;

    return (
        <>
            <Navigation match={props.match} />
            {secure}
        </>
    );

}

export default Layout