import React, { useState } from 'react'
import { FormControl, InputLabel, Input, Button, FormHelperText, makeStyles } from '@material-ui/core'
import { useForm, Controller } from "react-hook-form";
import axios from '../../../../axios-instance/backendAPI'
import moment from "moment";
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    btn: {
        width: '15%'
    },
    box: {
        margin: '15px 0'
    }
}));

export default function AddMarket(props) {

    const classes = useStyles();
    const { handleSubmit, control } = useForm();
    const [runners, setRunners] = useState([1, 2])
    const addRunner = () => {
        const newRunners = [...runners, runners.length + 1]
        setRunners(newRunners)
    }
    const deleteRunner = () => {

        if (runners.length <= 2)
            return

        const newRunners = [...runners]
        newRunners.pop()
        setRunners(newRunners)
    }
    const onSubmit = (data) => {

        const payload = {
            marketName: data.marketName,
            marketStartTime: data.marketStartTime
        }
        delete data.marketName
        delete data.marketStartTime
        payload.runners = []

        for (const key in data) {
            if (Object.hasOwnProperty.call(data, key)) {
                const element = data[key];
                payload.runners.push({ runnerName: element })
            }
        }
        payload.event = props.event.id

        axios.post('/admin/addManualMarket', payload, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
            .then(response => {
                if (response.data.success) {
                    props.hideModal()
                    alertify.success(response.data.message);
                } else {
                    alertify.error(response.data.message);
                }
            })
            .catch(err => {
                console.log(err);
            })

    }

    return (
        <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
            <h2 style={{ textDecoration: 'underline' }}>{props.event.name}</h2>
            <Controller
                name="marketName"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <FormControl className={classes.box}>
                        <InputLabel htmlFor="marketName">Market name</InputLabel>
                        <Input
                            id="marketName"
                            aria-describedby="my-helper-text"
                            value={value}
                            onChange={onChange}
                            error={!!error} />
                        <FormHelperText id="my-helper-text">{error ? error.message : null}</FormHelperText>
                    </FormControl>
                )}
                rules={{ required: 'Market name required' }}
            />
            <Controller
                name="marketStartTime"
                control={control}
                defaultValue={moment(new Date()).format("YYYY-MM-DDTHH:mm:ss")}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <FormControl className={classes.box}>
                        <InputLabel htmlFor="marketStartTime">Market start time</InputLabel>
                        <Input
                            type='datetime-local'
                            id="marketStartTime"
                            aria-describedby="my-helper-text"
                            value={value}
                            onChange={onChange}
                            error={!!error} />
                        <FormHelperText id="my-helper-text">{error ? error.message : null}</FormHelperText>
                    </FormControl>
                )}
                rules={{ required: 'Market start time required' }}
            />
            {runners.map(runner => <Controller
                key={runner}
                name={"runnerName" + runner}
                control={control}
                defaultValue=""
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <FormControl className={classes.box}>
                        <InputLabel htmlFor={"runnerName" + runner}>Runner name</InputLabel>
                        <Input
                            id={"runnerName" + runner}
                            aria-describedby="my-helper-text"
                            value={value}
                            onChange={onChange}
                            error={!!error} />
                        <FormHelperText id="my-helper-text">{error ? error.message : null}</FormHelperText>
                    </FormControl>
                )}
                rules={{ required: 'Runner name required' }}
            />)}
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Button onClick={addRunner}>
                    Add Runner
                </Button>
                <Button onClick={deleteRunner} style={{ display: runners.length <= 2 ? 'none' : 'inline' }}>
                    Delete Runner
                </Button>
            </div>
            <Button
                type='submit'
                variant="contained"
                className={classes.btn}
            >Submit</Button>
        </form>
    )
}
