import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Login from "./components/Login/Login";
import Layout from "./components/Layout/Layout";

const App = () => {

  return (<Router>
      <Switch>
        <Route path="/login" exact component={Login} />
        <Route path="/" component={Layout} />
      </Switch>
    </Router>
  );
};

export default App;