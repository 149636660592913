import React, { useState, useEffect } from 'react';
import axios from '../../../axios-instance/backendAPI';
import SportRow from './SportRow'
import { Table, TableCell, TableRow, TableHead, TableBody, TableContainer, Paper } from '@material-ui/core';
import classes from './Sport.module.css';
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';

export default function Sports() {

    const [existing, setExisting] = useState([]);
    const [existDisable, setExistDisable] = useState(false);
    const [addDisable, setAddDisable] = useState(false);
    const [currentId, setCurrentId] = useState(null)
    const [update, setUpdate] = useState(false)
    const [newS, setNewS] = useState([]);

    useEffect(() => {
        getExistingSports()
        getCurrentSports()
    }, [update])

    const getExistingSports = () => {

            axios.get('/admin/getSports', { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
                .then(response => {
                    if (response.data.success) {
                        setExisting(response.data.data)
                    }
                })
                .catch(error => {
                    console.log(error);
                })

    }
    const getCurrentSports = () => {

            axios.get('/admin/getBetfairSports', { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
                .then(response => {
                    if (response.data.success) {
                        setNewS(response.data.data)
                    }
                })
                .catch(error => {
                    console.log(error);
                })
    }
    const addSports = (payload) => {

        setAddDisable(true)
        setCurrentId(payload.eventType.id)

        axios.post('/admin/addSport', payload, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
            .then(response => {
                if (response.data.success) {
                    alertify.success(response.data.message);
                    setUpdate(prevValue => !prevValue);
                    setAddDisable(false);
                } else {
                    alertify.error(response.data.message);
                }
            })
            .catch(error => {
                setAddDisable(false);
                console.log(error);
            })
    }
    const removeSport = (id) => {

        setExistDisable(true)
        setCurrentId(id)

        axios.delete('/admin/deleteSport/' + id, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
            .then(response => {
                if (response.data.success) {
                    alertify.success(response.data.message);
                    setUpdate(prevValue => !prevValue);
                    setExistDisable(false)
                } else {
                    alertify.error(response.data.message);
                }
            })
            .catch(error => {
                setExistDisable(false)
                console.log(error);
            })
    }

    let existingSports = null, newSports = null;
    if (existing.length) {
        existingSports = existing.map(sport => {
            return (
                <SportRow
                    key={sport.eventType.id}
                    id={sport.eventType.id}
                    name={sport.eventType.name}
                    marketCount={sport.marketCount}
                    disable={currentId === sport.eventType.id ? existDisable : false}
                    action={() => { removeSport(sport.eventType.id) }}
                    sign='-'
                />)
        })
    }
    else {
        existingSports = <TableRow><TableCell colSpan='4'>No data</TableCell></TableRow>
    }

    if (newS.length) {
        newSports = newS;
        existing.forEach(existingSports => {
            let index = newSports.findIndex(sport => sport.eventType.id === existingSports.eventType.id);
            if (index !== -1)
                newSports.splice(index, 1);
        })
        newSports = newSports.map(sport => {
            return (<SportRow
                key={sport.eventType.id}
                id={sport.eventType.id}
                name={sport.eventType.name}
                marketCount={sport.marketCount}
                disable={currentId === sport.eventType.id ? addDisable : false}
                action={() => {
                    addSports(sport)
                }}
                sign='+'
            />)
        })
    }
    else {
        newSports = <TableRow><TableCell colSpan='4'>No data</TableCell></TableRow>
    }

    return (
        <div className={classes.Right}>
            <div style={{ margin: '0 10px' }}>
                <h3>Existing Sports</h3>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table" className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell>id</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Market Count</TableCell>
                                <TableCell>Remove</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {existingSports}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <div style={{ margin: '10px' }}>
                <h3>Add new Sport</h3>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>id</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Market Count</TableCell>
                                <TableCell>Add</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {newSports}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    )
}