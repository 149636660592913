import React from 'react';
import { Button, TableCell, TableRow, } from '@material-ui/core';

const SeriesRow = (props) => {

    return (
        <TableRow>
            <TableCell>{props.id}</TableCell>
            <TableCell
                style={{ cursor: props.redirectToMatches ? 'pointer' : 'default' }}
                onClick={() => {
                    if (props.redirectToMatches) {
                        props.redirectToMatches()
                    }
                }}>{props.name}</TableCell>
            <TableCell>{props.competitionRegion}</TableCell>
            <TableCell><Button
                variant="contained"
                onClick={props.action}
                disabled={props.disable}
            >{props.sign}</Button></TableCell>
        </TableRow >
    )
}
export default SeriesRow;