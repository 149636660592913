import React, { useState, useEffect } from 'react'
import { Button, Table, TableRow, TableCell, TableHead, TableBody, Paper, TableContainer, makeStyles } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import axios from '../../../axios-instance/backendAPI'
import Delete from './Delete';
import Modal from '../../UI/Modal/Modal'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
    },
    root: {
        top: '120px',
        position: 'relative'
    }
}));

export default function ManualMarkets() {

    const [markets, setMarkets] = useState([])
    const [activeId, setActiveId] = useState(false)
    const [show, setShow] = useState(false)
    const history = useHistory()
    const classes = useStyles();

    const hideModal = () => {
        setShow(false);
    }
    const deleteMarket = (marketId) => {
        setActiveId(marketId)
        setShow(true)
    }

    useEffect(() => {
        getMarkets()
    }, [])
    const getMarkets = () => {
        axios.get('/admin/getManualMarkets', { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
            .then(response => {

                if (response.data.success)
                    setMarkets(response.data.data)
            })
            .catch(error => {
                console.log(error);
            })
    }

    let marketRow = null
    if (markets.length) {
        marketRow = markets.map(market => {
            let event = new Date(market.marketStartTime);
            return (<TableRow
                key={market.marketId}
            >
                <TableCell>{market.marketId}</TableCell>
                <TableCell >{market.marketName}</TableCell>
                <TableCell >{market.event}</TableCell>
                <TableCell>{event.toLocaleString('en-IN')}</TableCell>
                <TableCell>
                    <Button
                        variant="contained"
                        onClick={() => {
                            history.push('/modifyOdds/' + market.marketId)
                        }}
                    >Modify</Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        startIcon={<DeleteIcon />}
                        onClick={() => {
                            deleteMarket(market.marketId)
                        }}
                    >Delete</Button>
                </TableCell>
            </TableRow>)
        })
    } else {
        marketRow = <TableRow><TableCell colSpan='6'>No data</TableCell></TableRow>
    }

    return (
        <div className={classes.root}>
            <Modal open={show} onClose={hideModal}>
                <Delete
                    marketId={activeId}
                    hideModal={hideModal}
                    getMarkets={getMarkets}
                />
            </Modal>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>id</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Event</TableCell>
                            <TableCell>Market Start time</TableCell>
                            <TableCell>All Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {marketRow}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}
