import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import axios from '../../../axios-instance/backendAPI'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '../../UI/Modal/Modal'
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';

const statusArray = ['ACTIVE', 'SUSPENDED', 'OPEN']
const runnerStatusArray = ['ACTIVE', 'INACTIVE', 'SUSPENDED']
const inplayArray = [true, false]

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        paddingTop: '8em',
        margin: '0 10px'
    },
    heading: {
        fontSize: '1.1em'
        // textDecoration: 'underline'
    },
    box: {
        display: 'flex',
        alignItems: 'center',
        margin: '0  0 3em 0',
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column"
        },
    },
    innerBox: {
        display: 'flex',
        alignItems: 'center',
        height: '50px',
        marginRight: '20px',
        [theme.breakpoints.down("sm")]: {
            marginRight: '0',
        }
    },
    runner: {
        display: 'grid',
        gridTemplateColumns: '60% 40%',
        alignItems: 'center',
        [theme.breakpoints.down("sm")]: {
            gridTemplateColumns: 'auto'
        }
    },
    price: {
        display: 'grid',
        gridTemplateColumns: '40% 60%',
        alignItems: 'center'
    },
    runnerName: {
        fontWeight: 'bold',
        marginRight: '5px',
        color: '#ffb80c',
    },
    inplay: {
        fontWeight: 'bold',
        marginRight: '5px',
        color: '#5565bd'
    },
    status: {
        fontWeight: 'bold',
        marginRight: '5px',
        color: 'rgba(255, 0, 106, 0.87)'
    },
    btn: {
        fontSize: '0.7em'
    },
    back: {
        color: 'black',
        backgroundColor: '#72bbef',
        fontWeight: 'bold'
    },
    lay: {
        color: 'black',
        backgroundColor: '#faa9ba',
        fontWeight: 'bold'
    }
}));

export default function ModifyOdds() {

    const [marketInfo, setMarketInfo] = useState({})
    const [runnerInfo, setRunnerInfo] = useState(null)
    const [show, setShow] = useState(false)
    const [price, setPrice] = useState()
    const [selectedRunner, setSelectedRunner] = useState()
    const classes = useStyles()
    const params = useParams()

    const hideModal = () => {
        setShow(false);
    }

    const handlePriceChange = (event) => {
        setPrice(event.target.value)
    }

    const isObjectEmpty = (obj) => {
        return Object.keys(obj).length === 0;
    }

    const getMarketInfo = () => {
        axios.get('/admin/marketInfo/' + params.marketId, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
            .then(response => {
                if (response.data.success)
                    setMarketInfo(response.data.data)
            })
            .catch(err => {
                console.log(err)
            })
    }

    const getRunnerInfo = () => {
        axios.get('/admin/runnerInfo/' + params.marketId, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
            .then(response => {
                if (response.data.success)
                    setRunnerInfo(response.data.data)
            })
            .catch(err => {
                console.log(err)
            })
    }

    const setMarketStatus = (status) => {
        const payload = {
            marketId: params.marketId,
            status: status
        }
        axios.put('/admin/setMarketStatus', payload, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
            .then(response => {
                if (response.data.success) {
                    getMarketInfo()
                    alertify.success(response.data.message)
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    const setMarketInplayStatus = (status) => {
        const payload = {
            marketId: params.marketId,
            status: status
        }
        axios.put('/admin/setMarketInplayStatus', payload, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
            .then(response => {
                if (response.data.success) {
                    getMarketInfo()
                    alertify.success(response.data.message)
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    const setRunnerStatus = (selectionId, status) => {
        const payload = {
            marketId: params.marketId,
            selectionId: selectionId,
            status: status
        }
        axios.put('/admin/setrunnerStatus', payload, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
            .then(response => {
                if (response.data.success) {
                    getRunnerInfo()
                    alertify.success(response.data.message)
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    const setRunnerOdds = (selectionId, odds) => {

        if(odds<0){
            return alertify.error('invalid odds')
        }

        const payload = {
            marketId: params.marketId,
            selectionId: selectionId,
            odds: parseFloat(odds.toFixed(2)),
            difference: marketInfo.difference
        }
        axios.put('/admin/setrunnerOdds', payload, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
            .then(response => {
                if (response.data.success) {
                    getRunnerInfo()
                    alertify.success(response.data.message)
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    const setDifference = (diff) => {

        if(diff<0.01){
            return alertify.error('invalid difference')
        }

        const payload = {
            marketId: params.marketId,
            difference: parseFloat((diff).toFixed(2))
        }
        axios.put('/admin/setDifference', payload, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
            .then(response => {
                if (response.data.success) {
                    getMarketInfo()
                    alertify.success(response.data.message)
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        getMarketInfo()
        getRunnerInfo()
    }, [])

    let aboutMarket = null, aboutRunners = null

    if (!isObjectEmpty(marketInfo)) {
        aboutMarket = <>
            <h2 className={classes.heading}>{marketInfo.marketName}({marketInfo.event})</h2>
            <hr />
            <div className={classes.box}>
                <div className={classes.innerBox}>
                    <p className={classes.status}>Status</p>
                    <ButtonGroup color="secondary" aria-label="outlined primary button group">
                        {statusArray.map(status => {
                            return <Button
                                className={classes.btn}
                                key={status}
                                variant={status === marketInfo.status ? 'contained' : 'outlined'}
                                onClick={() => {
                                    setMarketStatus(status)
                                }}
                            >{status}</Button>
                        })}
                    </ButtonGroup>
                </div>
                <div className={classes.innerBox}>
                    <p className={classes.inplay}>Inplay</p>
                    <ButtonGroup color="primary" aria-label="outlined primary button group">
                        {inplayArray.map(status => {
                            return <Button
                                className={classes.btn}
                                key={status}
                                variant={status === marketInfo.inplay ? 'contained' : 'outlined'}
                                onClick={() => {
                                    setMarketInplayStatus(status)
                                }}
                            >{status.toString()}</Button>
                        })}
                    </ButtonGroup>
                </div>
                <div className={classes.innerBox}>
                    <p className={classes.difference}>Difference</p>
                    <ButtonGroup color="primary" aria-label="outlined primary button group">
                        <Button
                            className={classes.btn}
                            onClick={() => {
                                setDifference(marketInfo.difference - 0.01)
                            }}
                        >-</Button>
                        <Button
                            className={classes.btn}
                        >{marketInfo.difference}</Button>
                        <Button
                            className={classes.btn}
                            onClick={() => {
                                setDifference(marketInfo.difference + 0.01)
                            }}
                        >+</Button>
                    </ButtonGroup>
                </div>
            </div>
        </>
    }

    if (runnerInfo) {
        aboutRunners = runnerInfo.map(runner => {

            return <div
                className={classes.runner}
                key={runner.selectionId}
            >
                <div className={classes.price}>
                    <p className={classes.runnerName}>{runner.runnerName}</p>
                    <ButtonGroup color="primary" aria-label="outlined primary button group">
                        <Button
                            className={classes.btn}
                            onClick={() => {
                                setRunnerOdds(runner.selectionId, runner.ex.availableToBack[0].price - 0.01)
                            }}
                        >-</Button>
                        <Button
                            className={[classes.btn, classes.back].join(' ')}
                            onClick={() => {
                                setSelectedRunner({
                                    selectionId: runner.selectionId,
                                    odds: runner.ex.availableToBack[0].price
                                })
                                setShow(true)
                            }}
                        >{runner.ex.availableToBack[0].price}</Button>
                        <Button
                            className={classes.btn}
                            onClick={() => {
                                setRunnerOdds(runner.selectionId, runner.ex.availableToBack[0].price + 0.01)
                            }}
                        >+</Button>
                        <Button
                            className={[classes.btn, classes.lay].join(' ')}
                        >{runner.ex.availableToLay[0].price}</Button>
                    </ButtonGroup>
                </div>
                <div className={classes.innerBox}>
                    <p className={classes.status}>Status</p>
                    <ButtonGroup color="secondary" aria-label="outlined primary button group">
                        {runnerStatusArray.map(status => {
                            return <Button
                                className={classes.btn}
                                key={status}
                                variant={status === runner.status ? 'contained' : 'outlined'}
                                onClick={() => {
                                    setRunnerStatus(runner.selectionId, status)
                                }}
                            >{status}</Button>
                        })}
                    </ButtonGroup>
                </div>
            </div>
        })
    }

    return (
        <div className={classes.root}>
            <Modal open={show} onClose={hideModal}>
                <div>
                    <input type='number' value={price!=null ? price : selectedRunner?.odds} onChange={handlePriceChange} />
                    <Button
                        onClick={() => {
                            setRunnerOdds(selectedRunner.selectionId, parseFloat(price))
                            hideModal()
                        }}
                    >Submit</Button>
                </div>
            </Modal>
            {aboutMarket}
            {aboutRunners}
        </div>
    )
}