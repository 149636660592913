import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import axios from '../../../axios-instance/backendAPI';
import SeriesRow from './SeriesRow'
import { Table, Button, TableCell, TableRow, TableHead, TableBody, TableContainer, Paper } from '@material-ui/core';
import Select from 'react-select'
import classes from './Series.module.css';
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';

const styleButton = {
    margin: '5px 2px',
    backgroundColor: '#ffb80c'
}

export default function Series() {

    const [existing, setExisting] = useState([]);
    const [existDisable, setExistDisable] = useState(false);
    const [addDisable, setAddDisable] = useState(false);
    const [disableUpdate, setDisableUpdate] = useState(false);
    const [currentId, setCurrentId] = useState(null)
    const [newS, setNewS] = useState([]);
    const [update, setUpdate] = useState(true);
    const [options, setOptions] = useState([]);
    const [sport, setSport] = useState({ value: null, label: 'Select Sport' });
    const history = useHistory()

    useEffect(() => {
        axios.get('/admin/getSports', { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
            .then(response => {
                if (response.data.success) {

                    let newOptions = []
                    response.data.data.forEach(sport => {

                        newOptions.push({
                            value: sport.eventType.id,
                            label: sport.eventType.name
                        })
                    })
                    setOptions(newOptions)
                }
            })
            .catch(error => {
                console.log(error);
            })
    }, [])

    useEffect(() => {
        getExistingSeries()
        getCurrentSeries()
    }, [update])

    const redirectToMatches = (id, name) => {
        history.push('/matches/' + name + '/' + sport.value + '/' + id)
    }
    const handleChange = (sport) => {
        setSport(sport);
    }
    const handleSubmit = () => {
        if (!sport.value) {
            alert('Please select a sport');
            return
        }
        getExistingSeries();
        getCurrentSeries();
    }
    const getExistingSeries = () => {

        if (sport.value) {

            axios.get('/admin/getSeries/' + sport.value, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
                .then(response => {
                    if (response.data.success) {
                        setExisting(response.data.data)
                    }
                })
                .catch(error => {
                    console.log(error);
                })
        }

    }
    const getCurrentSeries = () => {
        if (sport.value) {

            axios.get('/admin/getBetfairSeries/' + sport.value, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
                .then(response => {
                    if (response.data.success) {
                        setNewS(response.data.data)
                    }
                })
                .catch(error => {
                    console.log(error);
                })
        }
    }
    const addSeries = (payload) => {

        setAddDisable(true)
        setCurrentId(payload.competition.id)
        payload.eventType = sport.value

        axios.post('/admin/addSeries', payload, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
            .then(response => {
                if (response.data.success) {
                    alertify.success(response.data.message);
                    setUpdate(prevValue => !prevValue);
                    setAddDisable(false);
                } else {
                    alertify.error(response.data.message);
                }
            })
            .catch(error => {
                setAddDisable(false);
                console.log(error);
            })
    }
    const removeSeries = (id) => {

        setExistDisable(true)
        setCurrentId(id)

        axios.delete('/admin/deleteSeries/' + id, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
            .then(response => {
                if (response.data.success) {
                    alertify.success(response.data.message);
                    setUpdate(prevValue => !prevValue);
                    setExistDisable(false)
                } else {
                    alertify.error(response.data.message);
                }
            })
            .catch(error => {
                setExistDisable(false)
                console.log(error);
            })
    }
    const updateMatch = () => {

        setDisableUpdate(true);
        axios.put('/admin/updateSeries', {}, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
            .then(response => {
                if (response.data.success) {
                    alertify.success(response.data.message);
                } else {
                    alertify.error(response.data.message);
                }
                setDisableUpdate(false);
            })
            .catch(error => {
                setDisableUpdate(false);
                console.log(error);
            })
    }

    let existingSeries = null, newSeries = null;
    if (existing.length) {
        existingSeries = existing.map(series => {
            return (
                <SeriesRow
                    key={series.competition.id}
                    id={series.competition.id}
                    name={series.competition.name}
                    competitionRegion={series.competitionRegion}
                    disable={currentId === series.competition.id ? existDisable : false}
                    redirectToMatches={() => { redirectToMatches(series.competition.id, series.competition.name) }}
                    action={() => { removeSeries(series.competition.id) }}
                    sign='-'
                />)
        })
    }
    else {
        existingSeries = <TableRow><TableCell colSpan='4'>No data</TableCell></TableRow>
    }

    if (newS.length) {
        newSeries = newS;
        existing.forEach(existingSeries => {
            let index = newSeries.findIndex(series => series.competition.id === existingSeries.competition.id);
            if (index !== -1)
                newSeries.splice(index, 1);
        })
        newSeries = newSeries.map(series => {
            return (<SeriesRow
                key={series.competition.id}
                id={series.competition.id}
                name={series.competition.name}
                competitionRegion={series.competitionRegion}
                disable={currentId === series.competition.id ? addDisable : false}
                action={() => {
                    addSeries(series)
                }}
                sign='+'
            />)
        })
    }
    else {
        newSeries = <TableRow><TableCell colSpan='4'>No data</TableCell></TableRow>
    }

    return (
        <div className={classes.Right}>
            <div className={classes.nav}>
                <Select
                    id='sport'
                    value={sport}
                    onChange={handleChange}
                    options={options}
                />
                <Button
                    variant="contained"
                    style={styleButton}
                    onClick={handleSubmit}
                >Submit
                      </Button>
            </div>
            <div style={{ margin: '0 10px' }}>
                <h3>Existing Series <Button
                    onClick={updateMatch}
                    variant='contained'
                    style={styleButton}
                    disabled={disableUpdate}
                >Update</Button></h3>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table" className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell>id</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Competition Region</TableCell>
                                <TableCell>Remove</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {existingSeries}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <div style={{ margin: '10px' }}>
                <h3>Add new Series</h3>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>id</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Competition Region</TableCell>
                                <TableCell>Add</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {newSeries}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    )
}