import React from 'react';
import { Button, TableCell, TableRow, } from '@material-ui/core';

const MarketRow = (props) => {

    return (
        <TableRow>
            <TableCell>{props.id}</TableCell>
            <TableCell>{props.name}</TableCell>
            <TableCell>{props.marketStartTime.toLocaleString('en-IN')}</TableCell>
            <TableCell><Button
                variant="contained"
                onClick={props.action}
                disabled={props.disable}
            >{props.sign}</Button></TableCell>
        </TableRow>
    )
}
export default MarketRow;