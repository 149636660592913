import React, { useState, useEffect } from 'react';
import axios from '../../../axios-instance/backendAPI';
import { Button, Table, TableRow, TableCell, TableHead, TableBody, Paper, TableContainer } from "@material-ui/core";
import Modal from '../../UI/Modal/Modal'
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import Delete from './Actions/Delete';
import Add from './Actions/AddMarket';
import { useHistory, useParams } from 'react-router-dom';
import classes from './Matches.module.css'

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
    },
    table: {
        minWidth: 650,
    }
}));

const Matches = () => {

    const classesButton = useStyles();
    const [matches, setMatches] = useState([])
    const [activeId, setActiveId] = useState(false)
    const [show, setShow] = useState(false)
    const [deleteMatch, setDelete] = useState(false)
    const [add, setAdd] = useState(false)
    const params = useParams()
    const history = useHistory()

    const hideModal = () => {
        setShow(false);
    }

    useEffect(() => {
        getMatches()
    }, []);

    const redirectToMarkets = (id, name) => {
        history.push('/markets/' + id + '/' + name)
    }

    const showDelete = (matchId) => {

        setDelete(true)
        setAdd(false)
        setShow(true)
        setActiveId(matchId)
    }
    const showAdd = (matchId) => {

        setAdd(true)
        setDelete(false)
        setShow(true)
        setActiveId(matchId)
    }
    const getMatches = () => {

        axios.get('/admin/getMatches/' + params.eventType + '/' + params.seriesId, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
            .then(response => {

                if (response.data.success)
                    setMatches(response.data.data)
            })
            .catch(error => {
                console.log(error);
            })

    }

    let matchRow = null, toRender = null;

    if (deleteMatch) {

        toRender = <Delete
            eventId={activeId}
            hideModal={hideModal}
            getMatches={getMatches}
        />
    }
    else if (add) {
        toRender = <Add
            eventId={activeId}
            hideModal={hideModal}
        />
    }

    if (matches.length) {
        matchRow = matches.map(match => {
            let event = new Date(match.event.openDate);
            return (<TableRow
                key={match.event.id}
            >
                <TableCell>{match.event.id}</TableCell>
                <TableCell
                    className={classes.pointer}
                    onClick={() => {
                        redirectToMarkets(match.event.id, match.event.name)
                    }}
                >{match.event.name}</TableCell>
                <TableCell>{event.toLocaleString('en-IN')}</TableCell>
                <TableCell>
                    <Button
                        variant="contained"
                        onClick={() => {
                            showAdd(match.event)
                        }}>Add Market</Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        className={classesButton.button}
                        startIcon={<DeleteIcon />}
                        onClick={() => {
                            showDelete(match.event.id)
                        }}>Delete</Button>
                </TableCell>
            </TableRow>)
        })
    } else {
        matchRow = <TableRow><TableCell colSpan='6'>No data</TableCell></TableRow>
    }
    return (
        <div className={classes.root}>
            <Modal open={show} onClose={hideModal}>
                {toRender}
            </Modal>
            <div style={{ margin: '0 10px' }}>
                <h2 className={classes.heading}>Matches of {params.seriesName}</h2>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>id</TableCell>
                                <TableCell>Match Name</TableCell>
                                <TableCell>Open Date</TableCell>
                                <TableCell>All Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {matchRow}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    )
}

export default Matches