import axios from '../../axios-instance/backendAPI';
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import * as actionTypes from './actionTypes';

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    };
};

export const authSuccess = (token) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        token: token
    };
};

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    };
};

export const logout = () => {
    localStorage.removeItem('token');
    return {
        type: actionTypes.AUTH_LOGOUT,
        path: '/login'
    };
};

export const checkAuthTimeout = (expirationTime) => {
    return dispatch => {
        setTimeout(() => {
            dispatch(logout());
        }, expirationTime * 1000);
    };
};

export const auth = (payload) => {
    return async dispatch => {
        dispatch(authStart());

        
        let url = '/admin/login';

        axios.post(url, payload)
            .then(response => {
                if (response.data.success) {

                    alertify.success(response.data.message);
                    localStorage.setItem('token', response.data.token);
                    dispatch(authSuccess(response.data.token));
                    dispatch(checkAuthTimeout(response.data.expiresIn));
                    dispatch(setAuthRedirectPath('/'))
                } else {
                    alertify.error(response.data.message);
                    dispatch(authFail(response.data.message));
                }
            })
            .catch(err => {
                dispatch(authFail(err));
            });
    };
};

export const setAuthRedirectPath = (path) => {
    return {
        type: actionTypes.SET_AUTH_REDIRECT_PATH,
        path: path
    };
};

export const authCheckState = () => {
    return dispatch => {
        const token = localStorage.getItem('token');
        if (!token) {
            dispatch(logout());
        } else {

            let url = '/admin/authCheck'

            axios.get(url, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
                .then(response => {
                    
                    if (response.data.success) {

                        const expirationDate = new Date(new Date(response.data.data.loginTime).getTime() + response.data.data.expiresIn * 1000);

                        if (expirationDate <= new Date()) {
                            dispatch(logout());
                        } else {
                            dispatch(authSuccess(localStorage.getItem('token')));
                            dispatch(checkAuthTimeout((expirationDate.getTime() - new Date().getTime()) / 1000));
                        }
                    }else{
                        dispatch(logout());
                    }
                })
                .catch(err => {
                    dispatch(authFail(err));
                })
        }
    };
};
