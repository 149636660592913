import React, { useState, useEffect } from "react";
import Drawer from "@material-ui/core/Drawer";
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
import axios from '../../../axios-instance/backendAPI';
import Sports from './Sports/Sports';
import CircularProgress from '@material-ui/core/CircularProgress';
import classes from './Drawer.module.css';

export default function DrawerLab() {

  const [open, setopen] = useState(false);
  const [sports, setSports] = useState(null)

  useEffect(() => {
    axios.get('/admin/getBetfairSports', { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
      .then(response => {
        if (response.data.success)
          setSports(response.data.data)
      })
      .catch(error => {
        console.log(error);
      })
  }, [])

  const toggleBar = () => {
    setopen(!open);
  };

  let allSports = <CircularProgress />
  if (sports) {
    allSports = sports.map(sport => {
      return <Sports
        id={sport.eventType.id}
        key={sport.eventType.id}
        name={sport.eventType.name} />
    })
  }

  return (
    <div>
      <IconButton
        aria-label="open drawer"
        onClick={toggleBar}
        edge="start"
      >
        <MenuIcon />
      </IconButton>
      <Drawer anchor="left" open={open} onClose={toggleBar}>
        <div className={[classes.LeftSection, classes.fancyScroll].join(' ')}>
          {allSports}
        </div>
      </Drawer>
    </div>
  );
}