import React from 'react';
import classes from './Navigation.module.css';
import { NavLink, useHistory } from 'react-router-dom';
import {
    Grow,
    Popper,
    Paper,
    MenuList,
    MenuItem,
    Button,
    ClickAwayListener
} from "@material-ui/core";
import DrawerLab from '../Drawer/Drawer';
import axios from '../../../axios-instance/backendAPI';
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';

const Navigation = () => {

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const history = useHistory()

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    }

    const updateToken = () => {

        axios.put('/admin/updateToken', {}, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
            .then(response => {
                if (response.data.success)
                    alertify.success(response.data.message)
                else
                    alertify.error(response.data.message)
            })
    }

    return (
        <div className={classes.wrapper}>
            <div className={classes.multi_color_border}></div>
            <div className={classes.top_nav}>
                <DrawerLab />
                <div>
                    <Button
                        ref={anchorRef}
                        aria-controls={open ? "menu-list-grow" : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}
                    >
                        <span className={classes.right}>Admin</span>
                    </Button>
                    <Popper
                        open={open}
                        anchorEl={anchorRef.current}
                        role={1}
                        transition
                        disablePortal
                    >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin:
                                        placement === "bottom" ? "center top" : "center bottom",
                                }}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList
                                            className={classes.menu}
                                            autoFocusItem={open}
                                            id="menu-list-grow"
                                        >
                                            <MenuItem onClick={() => {
                                                updateToken()
                                                handleToggle()
                                            }}>
                                                <i className="fas fa-key"></i>&nbsp;Update Token
                                            </MenuItem>
                                            <MenuItem onClick={() => {
                                                history.push('/changePassword')
                                                handleToggle()
                                            }}>
                                                <i className="fas fa-key"></i>&nbsp;Change Password
                                            </MenuItem>
                                            <MenuItem onClick={() => {
                                                localStorage.removeItem('token')
                                                history.push('/login')
                                            }}>
                                                <i className="fas fa-sign-out-alt"></i>&nbsp;Logout
                                            </MenuItem>
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </div>
            </div>
            <div className={classes.bottom_nav}>
                <ul>
                    <li><NavLink to={'/sports'} activeClassName={classes.active}>Sports</NavLink></li>
                    <li><NavLink to={'/series'} activeClassName={classes.active}>Series</NavLink></li>
                    <li><NavLink to={'/allMatches'} activeClassName={classes.active}>Matches</NavLink></li>
                    <li><NavLink to={'/'} exact activeClassName={classes.active}>Manual Markets</NavLink></li>
                    <li><NavLink to={'/test'} activeClassName={classes.active}>Test</NavLink></li>
                </ul>
            </div>
        </div>
    );
}
export default Navigation