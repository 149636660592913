import React, { useEffect, useState } from 'react';
import Tree from 'react-animated-tree';
import { ListItem, ListItemText } from "@material-ui/core";
import axios from '../../../../../axios-instance/backendAPI';
import CircularProgress from '@material-ui/core/CircularProgress';

const Series = (props) => {

    const [matches, setMatches] = useState(null)

    useEffect(() => {
        axios.get('/admin/getBetfairMatches/' + props.eventId + '/' + props.id, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
            .then(response => {
                if (response.data.success)
                    setMatches(response.data.data)
            })
            .catch(error => {
                console.log(error);
            });
    }, [])

    let allMatches = <CircularProgress />
    if (matches) {
        allMatches = matches.map(match => {
            let event = new Date(match.event.openDate);
            return <ListItem
                key={match.event.id}
            >
                <ListItemText
                    primary={match.event.name + '(' + event.toLocaleString('en-IN') + ')'}
                />
            </ListItem>
        })
    }
    return (
        <Tree content={props.name} open style={{ overflowX: 'scroll' }}>
            {allMatches}
        </Tree>
    )
}

export default Series