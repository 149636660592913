import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from '../../../axios-instance/backendAPI';
import MarketRow from './MarketRow';
import { Table, TableCell, TableRow, TableHead, TableBody, TableContainer, Paper } from '@material-ui/core';
import classes from './Market.module.css';
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';

export default function Markets() {

    const [existing, setExisting] = useState([]);
    const [existDisable, setExistDisable] = useState(false);
    const [addDisable, setAddDisable] = useState(false);
    const [currentId, setCurrentId] = useState(null);
    const [update, setUpdate] = useState(false);
    const [newM, setNewM] = useState([]);
    const params = useParams();

    useEffect(() => {
        getExistingMarkets()
        getCurrentMarkets()
    }, [update])

    const getExistingMarkets = () => {

        axios.get('/admin/getMarkets/' + params.eventId, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
            .then(response => {
                if (response.data.success) {
                    setExisting(response.data.data)
                }
            })
            .catch(error => {
                console.log(error);
            })

    }
    const getCurrentMarkets = () => {

        axios.get('/admin/getBetfairMarkets/' + params.eventId)
            .then(response => {
                if (response.data.success) {
                    setNewM(response.data.data)
                }
            })
            .catch(error => {
                console.log(error);
            })
    }
    const addMarket = (payload) => {

        setAddDisable(true)
        setCurrentId(payload.marketId)

        axios.post('/admin/addMarket', payload, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
            .then(response => {
                if (response.data.success) {
                    alertify.success(response.data.message);
                    setUpdate(prevValue => !prevValue);
                    setAddDisable(false);
                } else {
                    alertify.error(response.data.message);
                }
            })
            .catch(error => {
                setAddDisable(false);
                console.log(error);
            })
    }
    const removeMarket = (id) => {

        setExistDisable(true)
        setCurrentId(id)

        axios.delete('/admin/deleteMarket/' + id, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
            .then(response => {
                if (response.data.success) {
                    alertify.success(response.data.message);
                    setUpdate(prevValue => !prevValue);
                    setExistDisable(false)
                } else {
                    alertify.error(response.data.message);
                }
            })
            .catch(error => {
                setExistDisable(false)
                console.log(error);
            })
    }

    let existingMarkets = null, newMarkets = null;
    if (existing.length) {
        existingMarkets = existing.map(market => {
            return (
                <MarketRow
                    key={market.marketId}
                    id={market.marketId}
                    name={market.marketName}
                    marketStartTime={new Date(market.marketStartTime)}
                    disable={currentId === market.marketId ? existDisable : false}
                    action={() => { removeMarket(market.marketId) }}
                    sign='-'
                />)
        })
    }
    else {
        existingMarkets = <TableRow><TableCell colSpan='4'>No data</TableCell></TableRow>
    }

    if (newM.length) {
        newMarkets = newM;
        existing.forEach(existingMarket => {
            let index = newMarkets.findIndex(market => market.marketId === existingMarket.marketId);
            if (index !== -1)
                newMarkets.splice(index, 1);
        })
        newMarkets = newMarkets.map(market => {
            return (<MarketRow
                key={market.marketId}
                id={market.marketId}
                name={market.marketName}
                marketStartTime={new Date(market.marketStartTime)}
                disable={currentId === market.marketId ? addDisable : false}
                action={() => {
                    addMarket(market)
                }}
                sign='+'
            />)
        })
    }
    else {
        newMarkets = <TableRow><TableCell colSpan='4'>No data</TableCell></TableRow>
    }

    return (
        <div className={classes.Right}>
            <div style={{ margin: '0 10px' }}>
                <h2 className={classes.heading}>Existing Markets of {params.eventName}</h2>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>id</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Market Start time</TableCell>
                                <TableCell>Remove</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {existingMarkets}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <div style={{ margin: '10px' }}>
                <h3>Add new Markets</h3>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>id</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Market Start time</TableCell>
                                <TableCell>Add</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {newMarkets}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    )
}