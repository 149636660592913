import React, { useRef } from "react";
import classes from "./ChangePassword.module.css";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import axios from "../../../axios-instance/backendAPI";
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import * as actions from "../../../store/actions/index";

export default function ChangePassword() {
    const { register, handleSubmit, formState: { errors }, watch } = useForm();
    const dispatch = useDispatch();
    const password = useRef({});
    password.current = watch("newpassword", "");
    const onSubmit = (data) => {
        const payload = {
            old_password: data.oldpassword,
            new_password: data.newpassword
        }
        axios.put('/admin/changePassword', payload, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
            .then(response => {
                if (response.data.success) {
                    dispatch(actions.logout());
                    alertify.success(response.data.message);
                }
                else {
                    alertify.error(response.data.message);
                }
            })
            .catch(error => {
                console.log(error);
            })
    }
    return (
        <div className={classes.root}>
            <div className={classes.helper}>Change Password</div>
            <div className={classes.changepass}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={classes.table}>
                        <div className={classes.label}>
                            <label htmlFor="oldpassword">Old Password* </label>
                        </div>
                        <input
                            id="oldpassword"
                            name="oldpassword"
                            type="password"
                            className={classes.materialUIInput}
                            {...register('oldpassword', { required: "You must specify a password" })}
                            autoFocus
                        />
                        <br />

                    </div>
                    {errors.oldpassword && <p className={classes.p}>{errors.oldpassword.message}</p>}
                    <div className={classes.table}>
                        <div className={classes.label}>
                            <label>New Password* </label>
                        </div>
                        <input
                            name="newpassword"
                            type="password"
                            className={classes.materialUIInput}
                            {...register('newpassword', {
                                required: "You must specify a password",
                                pattern: {
                                    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,20}$/,
                                    message: 'Requirenments: Min 8 and max 20 characters, one uppercase, one lowercase and one number'
                                }
                            })}
                            autoComplete="off"

                        />
                        <br />

                    </div>
                    {errors.newpassword && <p className={classes.p}>{errors.newpassword.message}</p>}
                    <div className={classes.table}>
                        <div className={classes.label}>
                            <label>Retype New Password* </label>
                        </div>
                        <input
                            name="retypenewpassword"
                            type="password"
                            className={classes.materialUIInput}
                            {...register('retypenewpassword', {
                                validate: value =>
                                    value === password.current || "The passwords do not match"
                            })}
                            autoComplete="off"
                        />
                        <br />

                    </div>
                    {errors.retypenewpassword && <p className={classes.p}>{errors.retypenewpassword.message}</p>}
                    <div className={classes.button}>
                        <input className={classes.input} type="submit" />
                    </div>
                </form>
            </div>
        </div>
    );
}