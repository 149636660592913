import axios from 'axios'

const instance = axios.create({ baseURL: process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_API_URL :  process.env.REACT_APP_API_URL });

instance.interceptors.request.use(function (config) {

    config.metadata = { startTime: new Date() }
    return config;
}, function (error) {
    return Promise.reject(error);
});

instance.interceptors.response.use(function (response) {

    response.config.metadata.endTime = new Date()
    response.duration = response.config.metadata.endTime - response.config.metadata.startTime
    return response;
}, function (error) {
    error.config.metadata.endTime = new Date();
    error.duration = error.config.metadata.endTime - error.config.metadata.startTime;
    return Promise.reject(error);
});


export default instance;
